"use client"
import styled from "styled-components";
import { device } from "../../utils/deviceSizes";

export const NotificationsStyles = styled.div`
  /* margin: 1rem 0; */
  /* padding: 0 2rem; */
  position: relative;
  background: var(--lightGrey1);

  padding: 2rem 0;
  
  display: none;
  &.show {
    display: block;
  }

  @media ${device.laptopL} {
    background: var(--lightGrey1);
  }

  /* margin-bottom: 2rem; */

  .notifications-list {
    li {
      margin-bottom: 1rem;
      margin-left: 2rem;

      @media ${device.laptopL} {
        font-size: 1.8rem;
        padding: 1rem;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        /* border-left: 0.6rem solid var(--primary); */
        background: var(--primary);
        margin-right: 2rem;
        /* height: 10rem; */
        height: 2rem;
        /* height: 50%; */
        /* height: max-content; */
        width: 1rem;
      }
    }
  }
`;
