"use client"
import styled from 'styled-components';

export const LoginPageStyles = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;

`;
