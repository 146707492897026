"use client"
import useNotifications from '@apollo-custom/storage/appConfig/useNotifications';
import { NotificationsStyles } from '@components/styles/NotificationsStyles';
import React from 'react';

const Notifications = () => {
  const { notifications } = useNotifications();
  return (

    notifications?.length ? (
      <NotificationsStyles
        className={`notifications ${notifications.length ? "show" : ""}`}
      >
        <ul className="notifications-list">
          {notifications.map(
            ({ message, type }) =>
              type === "BASIC" && <li key={message}>{message}</li>
          )}
        </ul>
      </NotificationsStyles>
    ) : (
      ""
    )

  );
};

export default Notifications;