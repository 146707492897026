"use client"
import React, { use, useEffect } from "react";
import { NextPage } from "next";

import { useRouter, useSearchParams } from 'next/navigation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SignInResponse, SigninVars } from 'src/types/user';
import { SIGNIN_MUTATION } from '@apollo-custom/mutations';
import useForm from '@utils/useForm';
import { useErrorMessage } from '@utils/useErrorMessage';
import ErrorHandler from '@components/ErrorHandler';
import LoaderComponent from '@components/Ui/Loader';
import { useAuth } from 'src/context/AuthContext';
import Cookies from 'js-cookie';
import { LoginPageStyles } from '@styles/LoginPage';
import Notifications from '../category/[category]/[name]/notifications';
import Signin from '@components/Signin';

const Auth: NextPage = () => {

  const searchParams = useSearchParams()

  const { values: { email, password } = {}, updateValue } = useForm({
    email: '',
    password: '',
  });
  const [signIn, { data, error, loading }] = useMutation<
    SignInResponse,
    SigninVars
  >(SIGNIN_MUTATION, {
    variables: {
      email,
      password,
      
    },
  });
  const message = data?.signIn?.message;
  const router = useRouter()
  const { setToken } = useAuth(); // This hook is used inside a component function
  useEffect(() => {
    if (data && data.signIn && data.signIn.token) {
      setToken(data.signIn.token);

      Cookies.set('token', data.signIn.token, { expires: 7 }); // Expires in 7 days
      localStorage.setItem('token', data.signIn.token);
      window.location.reload();
      router.push('/home')
    }
  }, [data]);

  const errorMessage = useErrorMessage(error);
  const errorFromParams = searchParams.get("error");


  return (
    <LoginPageStyles>
      <Notifications />
      {errorFromParams && (
          <ErrorHandler message={errorFromParams || ""} />
      )}
      {error && <ErrorHandler message={errorMessage || ""} />}
      {message && (
        <p className="message">{message}</p>
      )}
      {loading ? <LoaderComponent className="" /> :
        (
          <Signin  signIn={signIn} email={email} password={password} updateValue={updateValue} loading={undefined} />
        )
      }
    </LoginPageStyles>
  );
};

export default Auth;
