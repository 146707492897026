"use client"
import React, { useEffect } from 'react';
import Link from 'next/link';

// APOLLO
import { useMutation } from '@apollo/client';
import { SIGNIN_MUTATION } from '../../lib/apollo/mutations';
import { isLoggedInVar } from '../../lib/apollo/storage/appConfig/apolloVars';

// TYPES
import { SignInResponse, SigninVars } from '../../types/user';

// COMPOENENTS
import ErrorHandler from '../ErrorHandler';

// UTILS
import { useErrorMessage } from '../../utils/useErrorMessage';
import useForm from '../../utils/useForm';

// STYLES
import { FormStyles } from '../styles/FormStyles';
import TeachableAuth from './TeachableAuth';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';

const Signin = ({ signIn, email, password, updateValue, loading }) => {
  const handleSubmitForm = (e) => {
    e.preventDefault();
    signIn();
  };

  const searchParams = useSearchParams()

  const t = useTranslations("login");
  return (
    <FormStyles id="signin-form" method="post" onSubmit={handleSubmitForm}>
    <>
        <div className="form-control">
          <label htmlFor="email">{t('emailLabel')}</label>
          <input
            autoCapitalize="none"
            autoCorrect="off"
            id="email"
            type="email"
            name="email"
            value={email}
            onChange={updateValue}
          />
        </div>
        <div className="form-control">
          <label htmlFor="password">{t('passwordLabel')}</label>
          <input
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={updateValue}
          />
        </div>
        <div className="buttons-container">
          <button id="signin-submit" type="submit" disabled={loading}>
            {loading ? t('loadingMessage') : t('submitButton')}
          </button>
          {searchParams.get('teachable') && <div>{t('teachableAuth')}</div>}
          <Link href="/reset" className="reset-password">
            {t('forgotPassword')}
          </Link>
        </div>
      </>

      <a href={t('guideLink')} download>
        {t('downloadGuide')}
      </a>
    </FormStyles>
  );
};

export default Signin;
